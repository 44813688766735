import React, {Fragment} from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import Main from "./components/Layout/Main";

function App () {

        return (
            <Router>
                <Fragment>
                    <Route path='/' component={Main}/>

                </Fragment>
            </Router>
        );
    }


export default (App);

