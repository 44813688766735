import React, {} from 'react';
import InstagramIcon from "@material-ui/icons/Instagram";

function Footer (props){

    return (
        <div className='footer'>
            <div className='icon-container pb-3 pt-3'>
                <a className='icon-links' href='https://www.instagram.com/spaceflightrelated/?hl=en'>
                    <InstagramIcon fontSize="large"/>
                </a>
            </div>
        </div>
    );
}

export default Footer;
