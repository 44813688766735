import React, {useEffect} from 'react';
import nectrJobs from '../../static/img/72984883_padded_logo.png'
import Nectar_Hospitality from '../../static/img/Nectar_Hospitality_capture.JPG'

function Work (props){

    useEffect(() => {
        props.setPage('work')
    });

    return (
        <div className='every-container'>
            <div className='work-container'>
                <div className='row'>
                    {/*<div className='col-12'>*/}
                    {/*    <div className="card" style={{width: 250}}>*/}
                    {/*        <img className="card-img-top" src={nectrJobs} alt="Card image cap"/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">Coming Soon</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='col-12'>
                        <a href='https://nectar-hospitality.com/'>
                            <img className="work-image" src={Nectar_Hospitality} alt="Card image cap"/>
                        </a>
                        <div className="work-title mt-2">nectar hospitality</div>
                    </div>
                    {/*<div className='col-4'>*/}
                    {/*    <div className="card" style={{width: 250}}>*/}
                    {/*        <img className="card-img-top" src={nectrJobs} alt="Card image cap"/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">Some quick example text to build on the card title and make up the*/}
                    {/*                bulk of the card's content.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className='col-4'>*/}
                    {/*    <div className="card" style={{width: 250}}>*/}
                    {/*        <img className="card-img-top" src={nectrJobs} alt="Card image cap"/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <p className="card-text">Some quick example text to build on the card title and make up the*/}
                    {/*                bulk of the card's content.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default Work;
