import React, {useEffect} from 'react';
import self from '../../static/img/IMG_4246.JPG'
import setPage from "./Main";


function About (props){

    useEffect(() => {
        props.setPage('about')
    });

    return (
        <div className='every-container'>
            <div className='about-container' data-aos="fade-right" data-aos-anchor=".me" data-aos-delay={props.page === 'about' ? "1" : "200"}>
                <div>
                    <div className='title-container'>fullstack developer</div>
                    <div className='title-container'>production assistant</div>
                    <div className='title-container'>bartender</div>
                    <div className='bio'>
                        <p>Enthusiastic web developer with a passion for creating beautiful and user-friendly solutions.
                            For the last 2.5 years I have dedicated my time creating NectrJobs.com, a job recruitment site for the hospitality and tourism industry.
                            Strong interest in backend web frameworks, cloud computing, and business management.
                        </p>
                    </div>
                </div>

            </div>
            <div>
                <img className='img-container' data-aos="fade-zoom-in"
                     id='me'
                     data-aos-easing="ease-in-sine"
                     data-aos-delay="400"
                     data-aos-offset="0" src={self} alt={'nothing here'}/>
            </div>
        </div>
    );
}

export default About;
