import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

export default function Header(props) {

    const isMobile = window.innerWidth <= 768;

    const header =
        <>
            <Link className='btn header-links' to={'/about'}>about</Link>
            <Link className="btn header-links" to={'/contact'}>contact</Link>
            <div className='name-container'>
                <Link className='btn my-name' onClick={() => [props.setPage('header'), props.setHeaderPosition(true)]} to={'/'} >Charles Ales</Link>
            </div>
            <Link className="btn header-links" to={'/work'}>work</Link>
            <Link className="btn header-links" to={'/skills'}>skills</Link>
        </>

    const mobileHeader =
        <div className="dropdown show">
            <a className="btn my-name dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Charles Ales
            </a>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <Link className='btn header-links' to={'/about'}>about</Link>
                <Link className="btn header-links" to={'/contact'}>contact</Link>
                <Link className="btn header-links" to={'/work'}>work</Link>
                <Link className="btn header-links" to={'/skills'}>skills</Link>
            </div>
        </div>


    return (
        <div>
            <div className={props.page === 'header' ? 'header-middle' : 'header-top'}>
                {isMobile ? mobileHeader : header}
            </div>
        </div>
    );
}
