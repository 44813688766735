import React, {useEffect, useState, Fragment} from 'react';
import Header from "./Header";
import About from "./About";
// import InstagramIcon from '@material-ui/icons/Instagram';
import {Route, Switch} from "react-router-dom";
import Contact from "./Contact";
import Work from "./Work";
import Skills from "./Skills";
import Footer from "./Footer";
import { MagicSpinner  } from "react-spinners-kit";

function Main() {

    const [loading, setLoading] = useState(true);
    const [headerPosition, setHeaderPosition] = useState(true);
    const [page, setPage] = useState('header');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timer);
    }, [loading]);

    const header = <Header
        page={page}
        headerPosition={headerPosition}
        setHeaderPosition={setHeaderPosition}
        setPage={setPage}
    />

    const about = <About
        page={page}
        setPage={setPage}
    />
    const contact = <Contact
        page={page}
        setPage={setPage}
    />
    const work = <Work
        page={page}
        setPage={setPage}
    />
    const skills = <Skills
        page={page}
        setPage={setPage}
    />


    return (
        <div>
            {/*<div className={loading ? 'preloader' : 'fade-out'}/>*/}
            <div className={loading ? 'preloader' : 'fade-out'}>
                <MagicSpinner size={150} color="#ffffff" loading={loading}/>
            </div>
            <div>
                {header}
            </div>
            <Switch>
                {/*<Route exact path='/' component={Main}/>*/}
                <Route exact path='/about'>{about}</Route>
                <Route exact path='/contact'>{contact}</Route>
                <Route exact path='/work'>{work}</Route>
                <Route exact path='/skills'>{skills}</Route>
            </Switch>
            <Footer/>
            {/*</div>*/}
        </div>
    );

}

export default Main;
