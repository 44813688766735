import React, {useEffect} from 'react';
import self from '../../static/img/IMG_4246.jpeg'


function Skills (props){

    useEffect(() => {
        props.setPage('skills')
    });

    return (
        <div className='skill-body'>
            <div className='skill-container' data-aos="fade-left" data-aos-anchor-placement="top-bottom">
                <div className='skills-text-header'>Python</div>
                <div className='skills-text'>django</div>
                <div className='skills-text'>django rest framework</div>
                <br/>

                <div className='skills-text-header'>Javascript</div>
                <div className='skills-text'>react</div>
                <div className='skills-text'>redux</div>
                <div className='skills-text'>next.js</div>
                <div className='skills-text'>express.js</div>
                <br/>

                <div className='skills-text-header'>PostgreSQL</div>
                {/*<br/>*/}
                <div className='skills-text-header'>Git</div>
                {/*<br/>*/}
                <div className='skills-text-header'>CSS</div>
                {/*<br/>*/}
                <div className='skills-text-header'>GCP</div>

            </div>
        </div>
    );
}

export default Skills;
