import React, {useEffect, useState} from 'react';
// import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
// import * as $ from 'jquery';
import { useForm } from "react-hook-form";

function Contact (props){

    useEffect(() => {
        props.setPage('contact')
    });

    const { register, formState: { errors }, handleSubmit } = useForm();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const emailSent = () =>
        // eslint-disable-next-line no-undef
        [$('#emailSentModal').modal('show'), //important: for $ to work you just need to hover and suppress 'no-undef'
            setName(''),
            setEmail(''),
            setSubject(''),
            setMessage(''),
            setLoading(false)]

    const submitMessage = async (e) => {
        // e.preventDefault();
        if (!email || !message) {
            // return toast.error('Please fill email, subject and message');
            return console.log('Please fill email, subject and message')
        }
        try {
            setLoading(true);
            const { data } = await axios.post(`/api/email`, {
                name,
                email,
                subject,
                message,
            });
            emailSent()

            // toast.success(data.message);
            console.log(data.message)
        } catch (err) {
            setLoading(false);
            // toast.error(
            //     err.response && err.response.data.message
            //         ? err.response.data.message
            //         : err.message
            // );
            console.log(err.response && err.response.data.message
                ? err.response.data.message
                : err.message)
        }
    };

    return (
        <div className='every-container'>
            {/*<ToastContainer position="bottom-center" limit={1} />*/}
            <div className='contact-container' data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                <div className='contact-form-title'>
                    Send me a message
                </div>
                <div className='message-form'>
                    <form onSubmit={handleSubmit(submitMessage)} className='mt-2'>
                        <div className="row no-gutters">
                            <div className="form-group">
                                <div className='message-form-label'>name</div>
                                <input
                                    {...register("name", { required: true })}
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    size="40"
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                />
                                {errors.name?.type === 'required' && <p className="alert alert-danger mt-2 mb-0">Your name is required</p>}
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="form-group">
                                <div className='message-form-label'>email</div>
                                <input
                                    {...register("email", { required: true })}
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    size="40"
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    // placeholder='Company@email.com'
                                />
                                {errors.email?.type === 'required' && <p className="alert alert-danger mt-2 mb-0">Your email is required</p>}
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="form-group">
                                <div className='message-form-label'>subject</div>
                                <input
                                    {...register("subject", { required: true })}
                                    type="text"
                                    className="form-control"
                                    name="subject"
                                    size="40"
                                    onChange={e => setSubject(e.target.value)}
                                    value={subject}
                                    // placeholder='Company@email.com'
                                />
                                {errors.subject?.type === 'required' && <p className="alert alert-danger mt-2 mb-0">A subject is required</p>}
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="form-group">
                                <div className='message-form-label'>message</div>
                                <textarea
                                    {...register("message", { required: true })}
                                    className="form-control"
                                    id="message"
                                    name="message"
                                    onChange={e => setMessage(e.target.value)}
                                    value={message}
                                    rows="4"
                                    cols="50"/>
                                {errors.message?.type === 'required' && <p className="alert alert-danger mt-2 mb-0">A message is required</p>}
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="form-group" style={{marginBottom: 60}}>
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Sending...' : 'Send'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal fade" id="emailSentModal" tabindex="-1" role="dialog" aria-labelledby="emailSentModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="emailSentModalLongTitle">Message Sent</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Thank you for reaching out!
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Contact;
